import axios, { AxiosResponse } from 'axios';
import { DetailOrderTransaction } from 'types';

const localService = axios.create({
  baseURL: 'http://localhost:3030',
});

export const sendLogsLocal = async (order: DetailOrderTransaction): Promise<AxiosResponse> => {
  const res = await localService.post('/service/logs', {
    payload: order,
  });
  return res.data;
};
