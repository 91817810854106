import { CUSTOMER_CATEGORY, CUSTOMER_TYPE } from 'constant';
import { Maybe } from 'types';
import { ICustomerInfo, IDiscount, PriceBook } from 'types/common.types';
import { Variant } from 'types/products.types';
import { IPromotions } from 'types/promotion.types';

const checkDayIsApplied = (dayApplied: number): boolean => {
  const dayBits = [64, 32, 16, 8, 4, 2, 1];
  const currentDay = new Date().getDay();

  for (let i = 0; i < dayBits.length; i++) {
    if (dayApplied & dayBits[i]) {
      if (i === 0 && currentDay === 0) return true; // Sunday
      if (currentDay === 7 - i) return true; // Other days
    }
  }

  return false;
};

// TODO: Refactoring this file name after finish all features
/**
 * Validates if a discount is applicable based on various criteria.
 * @param discount - The discount to validate
 * @param customer - Customer information
 * @param currentHours - Current time in hours
 * @returns boolean - True if the discount is valid, false otherwise
 */
export function validateDiscount(
  discount: IDiscount | PriceBook | IPromotions,
  customer: ICustomerInfo,
  currentHours: number
): boolean {
  // Check if the current date is within the discount's valid period
  const today = new Date();
  if (today < new Date(discount.start_date) || today > new Date(discount.end_date)) return false;

  // Verify if the discount is applicable for the current day
  if (!checkDayIsApplied(discount.days_applicable)) return false;

  // Create a Set of customer IDs for faster lookup
  const customerIds = new Set(discount.customers?.map((c) => c.c_id) || []);

  // Check customer-specific discounts
  if (discount.customer_type === CUSTOMER_TYPE.pelanggan) {
    return customerIds.has(customer?.contact_id ?? -1);
  }

  // Check customer category discounts
  if (discount.customer_type === CUSTOMER_TYPE.kategori_pelanggan) {
    const cid = Number(customer?.category_id);
    return (
      customerIds.has(cid) ||
      (customerIds.has(CUSTOMER_CATEGORY.walkin_customer) && customer === null) ||
      (customerIds.has(CUSTOMER_CATEGORY.new_customer) && customer !== null && customer === undefined)
    );
  }

  // Verify if the discount is applicable for the current time
  const times = discount.times || [];
  return (
    times.length === 0 ||
    times.some((time) => currentHours >= time.start_time && currentHours < time.end_time)
  );
}

/**
 * Get single pricebook not have default qty
 *
 * @param listItemPriceBook
 * @param customer
 * @param currentHours
 * @param locationId
 * @param itemsCart
 */
export function getSinglePriceBook(
  listItemPriceBook: PriceBook[],
  customer: ICustomerInfo,
  currentHours: number,
  locationId: number,
  itemsCart: Variant
): Maybe<PriceBook> {
  if (listItemPriceBook.length === 0) return null;

  const itemQuantity = Number(itemsCart.quantity);
  const firstQty = listItemPriceBook[0].min_unit;

  if (itemQuantity < firstQty) return null;

  return (
    listItemPriceBook
      .filter(
        (pricebook) =>
          pricebook.is_applied &&
          !pricebook.is_stopped &&
          pricebook.location_id === locationId &&
          validateDiscount(pricebook, customer, currentHours) &&
          itemQuantity >= pricebook.min_unit
      )
      .sort((a, b) => b.min_unit - a.min_unit || a.price_book_detail_id - b.price_book_detail_id)[0] || null
  );
}

/**
 * Get pricebooks and return to array
 *
 * @param listItemPriceBook
 * @param customer
 * @param currentHours
 * @param locationId
 * @param itemsCart
 */
export function getPriceBooks(
  listItemPriceBook: PriceBook[],
  customer: ICustomerInfo,
  currentHours: number,
  locationId: number
): PriceBook[] {
  return listItemPriceBook
    .filter(
      (priceBook) =>
        priceBook.is_applied &&
        !priceBook.is_stopped &&
        priceBook.location_id === locationId &&
        validateDiscount(priceBook, customer, currentHours)
    )
    .sort((a, b) => {
      if (a.min_unit === b.min_unit) {
        return a.price_book_detail_id - b.price_book_detail_id;
      }
      return b.min_unit - a.min_unit;
    })
    .reduce((acc: PriceBook[], current) => {
      if (acc.length === 0 || current.min_unit !== acc[acc.length - 1].min_unit) {
        acc.push(current);
      } else {
        acc[acc.length - 1] = current;
      }
      return acc;
    }, []);
}

/**
 * Get single pricebook not have default qty
 *
 * @param listItemPriceBook
 * @param customer
 * @param currentHours
 * @param locationId
 * @param itemsCart
 */
export function getSingleCartPriceBook(
  listItemPriceBook: PriceBook[],
  itemsCart: Variant,
  customer: ICustomerInfo,
  currentHours: number
): Maybe<PriceBook> {
  const itemQuantity = Number(itemsCart.quantity);
  const firstQty = listItemPriceBook.length > 0 ? listItemPriceBook[0].min_unit : 0;

  if (itemQuantity < firstQty) return null;

  return (
    listItemPriceBook
      .filter(
        (priceBook) =>
          priceBook.is_applied &&
          !priceBook.is_stopped &&
          validateDiscount(priceBook, customer, currentHours) &&
          itemQuantity >= priceBook.min_unit
      )
      .sort((a, b) => b.min_unit - a.min_unit)[0] || null
  );
}

export function removeDuplicates(arr: any[], key: string): any[] {
  return arr.reduce((unique, item) => {
    if (!unique.find((obj: any) => obj[key] === item[key])) {
      unique.push(item);
    }
    return unique;
  }, []);
}
