import config from 'constant';
import { useCommon, useGetDiscount, useGetPosSetting, useInitProducts } from 'hooks';
import * as React from 'react';
import { setPercentDownload, setSyncPayload } from 'redux/reducer/registers';
import { setStructSetting } from 'redux/reducer/settings';
import commonRequest from 'services/http/common.request';
import { db } from 'services/indexdb/connection';

import { useAppDispatch, useAppSelector } from './redux';
import useGetBatchNumber from './useGetBatchNumber';
import useGetPricebook from './useGetPricebook';
import useGetSerialNumber from './useGetSerialNumber';

type initProps = {
  initDataPos: (locationId: number, currentPage: number, totalPage: number) => Promise<void>;
  isGetCustomer: boolean;
  isGetItem: boolean;
  isGetDiscount: boolean;
  isGetPricebook: boolean;
  isGetSerialNumber: boolean;
  isGetBatchNumber: boolean;
};

const useInitSync = (): initProps => {
  const {
    getPresets,
    isGetCustomer,
    getSalesmen,
    getPayments,
    getCustomers,
    getCourier,
    getContactCategory,
    getMarketplace,
    getAuthorizedUser,
  } = useCommon();
  const { isGetItem, getAllProduct, setPickedStock, getItemStock, mappingStockToItem } =
    useInitProducts();
  const registerInfo = useAppSelector((state) => state.register.registerInfo);
  const pageDownload = useAppSelector((state) => state.register.pageDownload);
  const mustDownloading = useAppSelector((state) => state.register.mustDownloading);
  const stepDownload = useAppSelector((state) => state.register.stepDownload);
  const locationPos = useAppSelector((state) => state.register.location);
  // const lastSynced = useAppSelector((state) => state.register.lastSynced);

  const { getDiscount, isGetDiscount } = useGetDiscount();
  const { getPriceBook, isGetPricebook } = useGetPricebook();
  const { getSerialNumber, isGetSerialNumber } = useGetSerialNumber();
  const { getBatchNumber, isGetBatchNumber } = useGetBatchNumber();
  const dispatch = useAppDispatch();
  const { getPosSetting } = useGetPosSetting();
  const LIMIT_PAYLOAD = 200;

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleSyncProcess = async <T,>(
    processName: string,
    res: any,
    updateFunction: (data: T) => Promise<void>,
    currentPage: number,
    totalPage: number,
    nextStep: string
  ) => {
    await updateFunction(res.result);

    dispatch(
      setSyncPayload({
        pageDownload: currentPage,
        mustDownloading: totalPage,
      })
    );
    const percentProcess = Math.round((Number(currentPage) / Number(totalPage)) * 100);

    const percentName =
      processName === 'batchnumber' || processName === 'serialnumber'
        ? processName.replace('number', '')
        : processName;

    dispatch(
      setPercentDownload({
        name: `percent${capitalize(percentName)}`,
        value: percentProcess,
      })
    );

    delayDownload();
    if ((percentProcess === 100 && totalPage === currentPage) || totalPage === 0) {
      dispatch(setPercentDownload({ name: `percent${capitalize(percentName)}`, value: 0 }));
      dispatch(setSyncPayload({ stepDownload: nextStep }));
    }
  };

  async function delayDownload(timer = 300) {
    await new Promise((resolve) => setTimeout(resolve, timer));
  }

  const checkSync = async (
    locationId: number,
    process: string,
    page: number,
    currentTotalPage: number,
    lastSync: string
  ) => {
    let _page = page - 1;
    let totalPage = currentTotalPage === 0 ? 1 : currentTotalPage;
    while (totalPage > _page) {
      _page++;
      const res = await commonRequest.initSync(
        locationId,
        registerInfo?.register_id as number,
        process,
        _page,
        lastSync
      );

      delayDownload();

      if (!res) {
        break;
      }

      if (_page === 1) {
        totalPage = Math.ceil(Number(res.result.totalCount) / LIMIT_PAYLOAD);
      }

      const percentProcess = Math.round((Number(_page) / Number(totalPage)) * 100);

      switch (process) {
        case '':
          await handleSyncProcess('discount', res, getDiscount, _page, totalPage, 'pricebook');
          break;
        case 'pricebook':
          await handleSyncProcess('pricebook', res, getPriceBook, _page, totalPage, 'serialnumber');
          break;
        case 'serialnumber':
          await handleSyncProcess(
            'serialnumber',
            res,
            getSerialNumber,
            _page,
            totalPage,
            'batchnumber'
          );
          break;
        case 'batchnumber':
          await handleSyncProcess('batchnumber', res, getBatchNumber, _page, totalPage, 'contact');
          break;
        case 'contact':
          await handleSyncProcess('contact', res, getCustomers, _page, totalPage, 'item');
          break;
        case 'item':
          await handleSyncProcess('item', res, getAllProduct, _page, totalPage, 'stock');
          break;
        case 'stock':
          setPickedStock(Number(percentProcess));
          await getItemStock(res.result);
          dispatch(
            setSyncPayload({
              pageDownload: _page,
              mustDownloading: totalPage,
            })
          );
          dispatch(setPercentDownload({ name: 'percentStock', value: percentProcess }));
          delayDownload();
          if ((Number(percentProcess) === 100 && totalPage === _page) || totalPage === 0) {
            dispatch(setPercentDownload({ name: 'percentStock', value: 0 }));
            dispatch(setSyncPayload({ stepDownload: 'merged-item' }));
          }
          break;
        default:
          break;
      }
    }
  };

  const initDataPos = async (locationId: number, page: number, totalPage: number) => {
    const res = await commonRequest.structPrint(locationId);
    dispatch(setStructSetting(res));
    await Promise.all([
      db.promotion.clear(),
      db.presetsdiscount.clear(),
      db.presetstax.clear(),
      db.salesmen.clear(),
      db.stock.clear(),
      db.paymentmethod.clear(),
      db.courier.clear(),
      getPosSetting(),
      getPresets('DISCOUNT'),
      getPresets('TAX'),
      getSalesmen(),
      getContactCategory(),
      getMarketplace(),
      getPayments(),
      getCourier(),
      getAuthorizedUser({
        location_id: locationId,
        permission_id: [config.ACL_AUTH_CANCEL, config.ACL_AUTH_RETURN, config.ACL_AUTH_DP],
      }),
    ]);

    if (stepDownload === '')
      await checkSync(locationId, stepDownload as string, page ?? 1, totalPage ?? 1, '');
  };

  React.useEffect(() => {
    if (stepDownload && stepDownload !== 'success' && stepDownload !== 'merged-item') {
      checkSync(
        Number(locationPos?.location_id),
        stepDownload,
        pageDownload ?? 1,
        mustDownloading ?? 1,
        ''
      );
    }

    if (stepDownload === 'merged-item') {
      mappingStockToItem();
    }
  }, [stepDownload]);

  return {
    initDataPos,
    isGetCustomer,
    isGetItem,
    isGetDiscount,
    isGetPricebook,
    isGetSerialNumber,
    isGetBatchNumber,
  };
};

export default useInitSync;
