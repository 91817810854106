import { Box, Button, HStack, Text, useToast, VStack } from '@chakra-ui/react';
import React from 'react';
interface NotificationProps {
  notification: (
    id: string,
    message: string,
    status: 'warning' | 'error' | 'success' | 'info',
    duration?: number
  ) => void;

  customNotification: (
    id: string,
    title: string,
    message: string,
    onOpenVoucher: () => void,
    duration?: number
  ) => void;
}

const useNotification = (): NotificationProps => {
  const toast = useToast();

  const notification = (
    id = '',
    message: string,
    status: 'warning' | 'error' | 'success' | 'info',
    duration = 5000
  ) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        description: message ?? 'Terjadi kesalahan pada aplikasi',
        status: status,
        duration,
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const customNotification = (
    id = '',
    title: string,
    message: string,
    onOpenVoucher: () => void,
    duration = 5000
  ) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        position: 'top-right',
        duration: duration || 5000,
        isClosable: true,
        render: ({ onClose }) => (
          <Box
            color='black'
            bg='white'
            px={3}
            borderRadius='lg'
            border='1px'
            boxShadow='shadow-item'
            borderColor='gray.200'
            display='flex'
            position='relative'
            textColor='black'
          >
            <VStack align='stretch' py={2} width='full'>
              <HStack className='flex justify-between'>
                <Text>{title ?? 'Terjadi kesalahan pada aplikasi'}</Text>
                <Button variant='' onClick={onClose} size='xs' height={4} width={6} left={2}>
                  X
                </Button>
              </HStack>
              <Text>{message}</Text>
              <Button
                variant=''
                onClick={() => {
                  onOpenVoucher();
                  onClose();
                }}
                bg='blue.500'
                color='white'
                height={8}
                width={16}
                alignSelf='flex-end'
              >
                Lihat
              </Button>
            </VStack>
          </Box>
        ),
      });
    }
  };

  return { notification, customNotification };
};

export default useNotification;
