import axios from 'axios';
import config from 'constant';

let WMS_API_URL = '';
if (process.env.NODE_ENV === 'production') {
  WMS_API_URL = config.API_WMS_PROD;
} else {
  WMS_API_URL = config.API_DEV;
}

export const WMS_API = WMS_API_URL;

const wmsRequest = axios.create({
  baseURL: WMS_API_URL,
});

wmsRequest.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default wmsRequest;
