import trackerRedux from '@openreplay/tracker-redux';
import { configureStore } from '@reduxjs/toolkit';
import { tracker } from 'lib/helpers';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducer';

/**
 * @description Redux store configuration for the application.
 * @param {Object} initialState - Initial state of the application. Defaults to an empty object.
 * @returns {Object} Redux store.
 */
const persistConfig: PersistConfig<any> = {
  key: 'jubelio-pos',
  version: 1,
  storage,
};

// persistReducer allows us to persist the state of the application.
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = [];

middleware.push(
  tracker.use(
    trackerRedux({
      actionType: (action) => action.type,
    })
  )
);
const enchancers = [...middleware];

// Create the store.
// the configureStore function takes in the middlewares array and the persistedReducer.
export const store = configureStore({
  reducer: persistedReducer,
  middleware: enchancers,
});

// Export the store.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
