import { ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import theme from 'theme/theme';

import './theme/index.css';
import './theme/print.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    {/* Provide Chakra-UI theme overrides */}
    <ChakraProvider theme={theme} resetCSS>
      {/* provider redux store */}
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
