import trackerAxios from '@openreplay/tracker-axios';
import LoadingScreen from 'components/state/LoadingScreen';
import { COMPANY_ID } from 'constant';
import { useGetClosure, useNotification, useOnlineStatus } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { tracker } from 'lib/helpers';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { userInfo } from 'redux/reducer/auth';
import { setOnlineStatus } from 'redux/reducer/registers';
import authRequest from 'services/http/auth.request';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

type AuthProps = {
  children: React.ReactNode;
};

const AuthInit: React.FC<AuthProps> = ({ children }: AuthProps) => {
  // ** Redux State
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const profileUser = useAppSelector((state) => state.auth);
  const isCashier = useAppSelector((state) => state.register.isCashier);

  const token = LocalStorageService.getItem('token');
  const { notification } = useNotification();
  const router = useHistory();
  const location = useLocation();
  const { getClosure } = useGetClosure();
  const { isOnline } = useOnlineStatus();

  const INTERVAL_TEN_MINUTES = 300000;

  const [isLoading, setLoading] = React.useState<boolean>(true);

  if (COMPANY_ID.includes(authState.currentCompany)) {
    tracker.use(
      trackerAxios({
        ignoreHeaders: false,
      })
    );
    tracker.start();
    tracker.setUserID(profileUser.user?.email ?? '');
  }

  const requestUser = React.useCallback(async () => {
    try {
      const wmsUrl = authState.is_wms_migrated ? 'pos-wms' : 'pos';
      const res = await authRequest.getUserInfo(wmsUrl);
      dispatch(userInfo(res));
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        notification('', 'Session anda berakhir', 'warning', 5000);
        router.push('/login');
      }
    }
  }, [token, authState.is_wms_migrated]);

  // update network status
  React.useEffect(() => {
    dispatch(setOnlineStatus(isOnline));
  }, [isOnline]);

  // update network status
  React.useEffect(() => {
    dispatch(setOnlineStatus(isOnline));
  }, [isOnline]);

  React.useEffect(() => {
    requestUser();
  }, [token]);

  // get last closure
  React.useEffect(() => {
    if (isCashier) {
      getClosure();
    }
  }, [location, isCashier]);

  React.useEffect(() => {
    const reqUserInfo = setInterval(() => requestUser(), INTERVAL_TEN_MINUTES);
    return () => {
      clearInterval(reqUserInfo);
    };
  }, [token]);

  React.useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
  }, []);

  return <React.Fragment>{isLoading ? <LoadingScreen /> : children}</React.Fragment>;
};

export default AuthInit;
