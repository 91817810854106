import React from 'react';
import { db } from 'services/indexdb/connection';
import { BatchNumber, BatchNumberRoot } from 'types/common.types';

type Props = {
  getBatchNumber: (batch: BatchNumberRoot) => Promise<void>;
  isGetBatchNumber: boolean;
  pickedBatchNumber: number;
  setPickedBatchNumber: (value: number) => void;
};

const useGetBatchNumber = (): Props => {
  const [pickedBatchNumber, setPickedBatchNumber] = React.useState<number>(0);
  const [isGetBatchNumber, setIsBatchNumber] = React.useState<boolean>(true);

  /**
   * Trigger when socket emit "download-batchnumber" from server
   * @param batchnumber
   */
  const getBatchNumber = async (batch: BatchNumberRoot): Promise<void> => {
    try {
      setIsBatchNumber(true);
      if (batch.data.length > 0) {
        await saveBatchnumber(batch.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Save batchnumber to IndexedDB with chunk of data
   * @param batchnumber
   * @returns {Promise<void>}
   */
  const saveBatchnumber = async (listBatchNumber: BatchNumber[]): Promise<void> => {
    try {
      await db.batchnumber.bulkAdd(listBatchNumber);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (pickedBatchNumber !== 0 && pickedBatchNumber === 100) {
      setIsBatchNumber(false);
      setPickedBatchNumber(0);
    }
  }, [pickedBatchNumber]);

  return {
    getBatchNumber,
    pickedBatchNumber,
    isGetBatchNumber,
    setPickedBatchNumber,
  };
};

export default useGetBatchNumber;
