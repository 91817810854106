import axios, { AxiosError, AxiosResponse } from 'axios';
import config from 'constant';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

import { WMS_API } from './wms-request';

let API_URL = '';
if (process.env.NODE_ENV === 'production') {
  API_URL = config.API_PROD;
} else {
  API_URL = config.API_DEV;
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response) {
    if (error.config && error.response && error.response.status === 401) {
      window.location.href = '/';
      LocalStorageService.clear();
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

function getBaseUrl() {
  // some logic to determine base URL here
  const isWms = LocalStorageService.getItem('is_pos_wms') === 'pos-wms';
  return Promise.resolve(isWms ? WMS_API : API_URL);
}

axios.interceptors.request.use(
  async (config) => {
    config.baseURL = await getBaseUrl();
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
axios.interceptors.response.use(onResponse, onResponseError);

export default axios;
