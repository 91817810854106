import { BatchNumber } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * This method is used to create a new batchnumber.
   *
   * @param {string} data - The data of the batchnumber.
   * @returns {Promise<void>}
   * @example batchnumber.addBatchNumber('batchnumber', [{id: "J&T REG", text: "J&T REG"}])}]);
   */
  addBatchNumber: async (data: BatchNumber[]): Promise<boolean> => {
    try {
      await db.batchnumber.bulkAdd(data);
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.reject(error);
    }
  },

  /**
   * This method is used to get all batchnumber.
   * @returns {Promise<IBatchNumber>}
   * @example with async - await batchnumber.getBatchNumber()
   * @example batchnumber.getBatchNumber().then(data => console.log(data))
   */
  getAll: async (): Promise<BatchNumber[]> => {
    try {
      return db.table('batchnumber').toArray();
    } catch (error: any) {
      return error;
    }
  },

  clear: async (): Promise<boolean> => {
    try {
      await db.table('batchnumber').clear();
      return Promise.resolve(true);
    } catch (error: any) {
      return error;
    }
  },

  getByItemId: async (id: number): Promise<BatchNumber[] | undefined> => {
    return db.batchnumber.where('item_id').equals(id).toArray();
  },
};
