import { PROMOTION_TYPES } from 'constant';
import promotionRequest from 'services/http/promotion.request';
import { db } from 'services/indexdb/connection';
import { Maybe } from 'types';
import { ICustomerInfo, IDiscount, PriceBook, SlashPrice } from 'types/common.types';
import { IItemCart } from 'types/products.types';
import {
  IGetPromotion,
  IGetPromotionData,
  IGetPromotionItem,
  IPaymentGetPromotion,
  IValidatePromotionList,
  ResPromotionTransaction,
} from 'types/promotion.types';
import { ILocations } from 'types/register.types';
import { ISalesState } from 'types/sales.types';
import {
  getAmountItem,
  getDiscountItem,
  getDiscountOutlet,
  getSaleTotalAfterItemDiscount,
} from 'utils';
import { getPriceBooks, getSingleCartPriceBook, validateDiscount } from 'utils/promotion';
import { validatePromoBuyXGetY } from 'utils/promotions/buyx-gety';
import { validatePromoFreeItems } from 'utils/promotions/free-items';
import { checkDiscountAmount, checkMinimalTransaction } from 'utils/promotions/minimal-transaction';

import { useAppSelector } from './redux';
import useCheckTime from './useCheckTime';

interface PropsPromotions {
  getSlashPrice: (
    items: IItemCart[],
    customer?: Maybe<ICustomerInfo>
  ) => Promise<SlashPrice | null>;
  getSlashPriceCart: (itemsCart: IItemCart[]) => Promise<SlashPrice | null>;
  getPricebookCart: (items: IItemCart[]) => Promise<PriceBook>;
  getPriceBookListByCustomer: (
    items: IItemCart[],
    customerInfo: ICustomerInfo
  ) => Promise<PriceBook>;
  getTransactionPromotion: (sales: ISalesState) => Promise<any>;
  getPromotionItem: (
    items: IItemCart[],
    grandTotal: number,
    payments?: IPaymentGetPromotion[]
  ) => Promise<ResPromotionTransaction>;
  getCurrentPromotion: () => Promise<any>;
  validatePromotion: (
    items: IItemCart[],
    grandTotal: number,
    payments?: IPaymentGetPromotion[],
    promotions?: IValidatePromotionList[]
  ) => Promise<{ isValid: boolean; message: string }>;
}

const usePromotions = (): PropsPromotions => {
  const location = useAppSelector((state) => state.register.location);
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const continuePayment = useAppSelector((state) => state.sales.continuePayment);
  const customerInfo = useAppSelector((state) => state.sales.customerInfo);
  const currentListPromotion = useAppSelector((state) => state.sales.currentListPromotion);
  const listValidVoucher = useAppSelector((state) => state.sales.listValidVoucher);
  const listAllPromotion = useAppSelector((state) => state.sales.listAllPromotion);
  const { currentHours } = useCheckTime();
  /**
   *  Get slash price for items
   */
  async function getSlashPrice(
    itemsCart: IItemCart[],
    customer?: Maybe<ICustomerInfo>
  ): Promise<SlashPrice | null> {
    if (continuePayment) return null;
    const discounts = await db.discount.toArray();
    const resCustomer = await db.customer.where('contact_id').equals(-1).first();
    const usedCustomer = customer || customerInfo || (resCustomer as ICustomerInfo);
    const slashPrices: SlashPrice = {} as SlashPrice;

    for (const item of itemsCart) {
      let discount = findApplicableDiscount(item.item_id, usedCustomer, discounts);
      if (!discount && customer) {
        discount = findApplicableDiscount(item.item_id, null, discounts);
      }

      if (!discount) continue;
      if (!isDiscountValid(discount)) continue;

      const itemKey = `item_${discount.item_id}`;
      const slashPriceKey = `slash_price_${itemKey}`;
      const promotionPrice = Number(discount.promotion_price);
      const quantity =
        listItemCart.find((i: IItemCart) => i.item_id === item.item_id)?.quantity || 1;

      if (promotionPrice >= 0 && promotionPrice < Number(item.sell_price ?? 0)) {
        slashPrices['discount_id'] = discount.discount_id;
        slashPrices[itemKey] = discount.item_id;
        slashPrices[slashPriceKey] = {
          ...discount,
          total_slash_price: Number(quantity) * (Number(item.sell_price) - Number(promotionPrice)),
        };
      }
    }

    return Object.keys(slashPrices).length > 0 ? slashPrices : null;
  }

  const findApplicableDiscount = (
    itemId: number,
    customer: Maybe<ICustomerInfo>,
    discounts: IDiscount[]
  ): IDiscount | null => {
    const applicableDiscount = discounts.filter((d) => d.item_id === itemId);

    if (!applicableDiscount) return null;

    const findValidDiscount = applicableDiscount.find((disc) =>
      validateDiscount(disc, customer as ICustomerInfo, currentHours)
    );

    return findValidDiscount ?? null;
  };

  const isDiscountValid = (discount: IDiscount): boolean => {
    return (
      discount.is_applied && !discount.is_stopped && discount.location_id === location?.location_id
    );
  };

  /**
   *  Get slash price for items
   */
  const getSlashPriceCart = async (itemsCart: IItemCart[]): Promise<SlashPrice | null> => {
    const listDiscountByItem: SlashPrice = {} as SlashPrice;
    const resCustomer = await db.customer.where('contact_id').equals(-1).first();
    if (continuePayment) return null;

    for (const items of itemsCart) {
      if (items.slash_price) {
        const ik = `item_${items.item_id}`;
        const slash_price = `slash_price_${ik}`;
        const usageDiscount = items.slash_price[slash_price];
        if (
          !validateDiscount(
            usageDiscount,
            customerInfo ?? (resCustomer as ICustomerInfo),
            currentHours
          )
        )
          return null;

        if (!usageDiscount.is_applied || usageDiscount.is_stopped) return null;
        if (usageDiscount.location_id !== location?.location_id) return null;

        const promotion_price = Number(usageDiscount.promotion_price);
        if (promotion_price >= 0) {
          if (promotion_price < Number(items.sell_price)) {
            const existingItem = listItemCart.find(
              (item: IItemCart) => item.item_id === items.item_id
            );
            let qty = 1;

            if (existingItem) qty = existingItem.quantity;

            listDiscountByItem['discount_id'] = usageDiscount.discount_id;
            listDiscountByItem[ik] = usageDiscount.item_id;
            listDiscountByItem[slash_price] = {
              ...usageDiscount,
              total_slash_price: Number(qty) * (Number(items.sell_price) - promotion_price),
            };
          }
        } else {
          listDiscountByItem['discount_id'] = usageDiscount.discount_id;
          listDiscountByItem[ik] = usageDiscount.item_id;
        }
      }
    }
    return Object.keys(listDiscountByItem).length > 0 ? listDiscountByItem : null;
  };

  /**
   * Get price book list for items
   */
  const getPricebookCart = async (items: IItemCart[]): Promise<PriceBook> => {
    const listPriceBookItem: PriceBook = {} as PriceBook;
    const resCustomer = await db.customer.where('contact_id').equals(-1).first();

    for (const item of items) {
      const ik = `item_${item.item_id}`;
      const price_book_id = `price_book_id_item_${item.item_id}`;
      const list_price_book = `list_price_book_item_${item.item_id}`;
      const retail_price = `retail_price_item_${item.item_id}`;
      const min_unit = `min_unit_item_${item.item_id}`;
      const findPricebookByItemId = await db.pricebook
        .where('item_id')
        .equals(item.item_id)
        .toArray();
      const priceBookPrice = getSingleCartPriceBook(
        findPricebookByItemId ?? [],
        item,
        (customerInfo ?? resCustomer) as ICustomerInfo,
        currentHours
      );

      listPriceBookItem[list_price_book] = item.list_price_book;
      if (priceBookPrice !== null) {
        listPriceBookItem[ik] = Number(priceBookPrice.item_id);
        listPriceBookItem[price_book_id] =
          item.list_price_book &&
          item.list_price_book.length > 0 &&
          item.list_price_book[0].price_book_id;
        listPriceBookItem[retail_price] = Number(priceBookPrice.retail_price);
        listPriceBookItem[min_unit] = Number(priceBookPrice.min_unit);
      } else {
        listPriceBookItem[ik] = Number(item.item_id);
        listPriceBookItem[retail_price] = Number(item.normal_price);
      }
    }

    return listPriceBookItem;
  };

  /**
   * Get price book list for items
   */
  const getPriceBookListByCustomer = async (
    items: IItemCart[],
    customerInfo: ICustomerInfo
  ): Promise<PriceBook> => {
    const populatePriceBook = await db.pricebook.toArray();
    const listPriceBookItem: PriceBook = {} as PriceBook;
    const resCustomer = await db.customer.where('contact_id').equals(-1).first();

    for (const item of items) {
      const ik = `item_${item.item_id}`;
      const price_book_id = `price_book_id_item_${item.item_id}`;
      const list_price_book = `list_price_book_item_${item.item_id}`;
      const retail_price = `retail_price_item_${item.item_id}`;
      const min_unit = `min_unit_item_${item.item_id}`;
      const itemPriceBook = populatePriceBook.filter(
        (pricebook: PriceBook) => pricebook.item_id === item.item_id
      );

      if (itemPriceBook.length > 0) {
        const priceBookList = getPriceBooks(
          itemPriceBook,
          (customerInfo ?? resCustomer) as ICustomerInfo,
          currentHours,
          location?.location_id as number
        );

        const priceBookPrice = getSingleCartPriceBook(
          priceBookList,
          item,
          customerInfo,
          currentHours
        );

        listPriceBookItem[list_price_book] = priceBookList;
        if (priceBookPrice !== null) {
          listPriceBookItem[ik] = item.item_id;
          listPriceBookItem[price_book_id] =
            priceBookList && priceBookList.length > 0 && priceBookList[0].price_book_id;
          listPriceBookItem[retail_price] = Number(priceBookPrice.retail_price);
          listPriceBookItem[min_unit] = Number(priceBookPrice.min_unit);
        } else {
          listPriceBookItem[ik] = Number(item.item_id);
          listPriceBookItem[retail_price] = Number(item.normal_price);
        }
      }
    }

    return listPriceBookItem;
  };

  const getPromotionItem = async (
    items: IItemCart[],
    grandTotal: number,
    payments?: IPaymentGetPromotion[]
  ): Promise<ResPromotionTransaction> => {
    const res = await db.customer.where('contact_id').equals(-1).first();
    const newItems: IGetPromotionItem[] = items?.map((item: IItemCart) => {
      const totalPrice = getAmountItem(item);
      const itemAmount = totalPrice - getDiscountItem(item, [], true, totalPrice);
      return { item_id: item.item_id, qty: item.quantity, amount: itemAmount };
    });

    const mapPromotion = listValidVoucher.map((v) => {
      return {
        promotion_id: v.promotion_id,
        voucher_code: v.voucher_code,
      };
    });

    const payload: IGetPromotion = {
      items: newItems,
      current_date: new Date(),
      location_id: location?.location_id ?? 0,
      customer: {
        customer_id: customerInfo ? customerInfo.contact_id : res?.contact_id ?? -1,
        customer_name: customerInfo
          ? customerInfo.contact_name
          : res?.contact_name ?? 'Pelanggan Umum',
        customer_category_id: customerInfo ? customerInfo.category_id : res?.category_id ?? 0,
      },
      grand_total: grandTotal ?? 0,
      payments,
      promotions: mapPromotion,
    };
    const resPromotion = await promotionRequest.getAvailablePromotion(payload);
    if (resPromotion.data.length === 0)
      return {
        allPromotion: [],
        itemPromotion: [],
        transactionPromotion: [],
        totalCount: resPromotion.data.length,
      };
    const filterOverlap: IGetPromotionData[] = resPromotion.data.filter(
      (p) => p.is_overlap === true
    );
    let promotionData: IGetPromotionData[] = [];
    if (resPromotion.data[0].is_overlap) {
      promotionData = filterOverlap;
    } else {
      promotionData.push(resPromotion.data[0]);
    }
    const filterDiscountPromotion = promotionData.filter(
      (p) => p.discount_reward && p.discount_reward.length > 0
    );

    const totalAfterDiscount = getSaleTotalAfterItemDiscount(listItemCart);
    const locationDiscount = getDiscountOutlet(totalAfterDiscount, location as ILocations);
    let saleAfterDiscount = totalAfterDiscount - locationDiscount;
    const transactionPromotion = [];
    for (const promotion of filterDiscountPromotion) {
      const p = checkDiscountAmount(promotion, saleAfterDiscount);
      if (p) {
        saleAfterDiscount = saleAfterDiscount - p.saleAfterDiscount;
        transactionPromotion.push(p);
      }
    }

    const filterItemPromotion = promotionData.filter(
      (p) =>
        p.product_reward &&
        p.product_reward.length > 0 &&
        !listAllPromotion.find((i) => i.promotion_id === p.promotion_id && i.skip_free_item)
    );

    const mapAllPromotion = resPromotion.data.map((p) => {
      const findPromotion = listAllPromotion.find(
        (i) => i.promotion_id === p.promotion_id && i.skip_free_item
      );
      if (findPromotion) {
        return {
          ...p,
          skip_free_item: findPromotion?.skip_free_item ?? false,
        };
      }
      return p;
    });

    return {
      allPromotion: mapAllPromotion,
      transactionPromotion,
      itemPromotion: filterItemPromotion,
      totalCount: resPromotion.data.length,
    };
  };

  // Promotion for Minimal Transaction, Free Item, Buy X Get Y
  const getTransactionPromotion = async (sales: ISalesState): Promise<any> => {
    const transactionPromotion = [];
    const populatePromotion = currentListPromotion;
    const resCustomer = await db.customer.where('contact_id').equals(-1).first();
    for (const promotion of populatePromotion) {
      if (
        !validateDiscount(promotion, (customerInfo ?? resCustomer) as ICustomerInfo, currentHours)
      )
        continue;

      let p = null;
      if (promotion.promotion_type === PROMOTION_TYPES.MinimalTransaction) {
        p = checkMinimalTransaction({
          promotion,
          itemCart: sales.listItemCart,
          location: location as ILocations,
        });
        if (p) transactionPromotion.push(p);
      } else if (promotion.promotion_type === PROMOTION_TYPES.FreeItemTransaction) {
        p = validatePromoFreeItems(sales, promotion, location as ILocations);
        if (p) transactionPromotion.push(p);
      } else if (promotion.promotion_type === PROMOTION_TYPES.BuyXGetY) {
        p = validatePromoBuyXGetY(promotion, listItemCart);
        if (p) transactionPromotion.push(p);
      }
    }

    return transactionPromotion;
  };

  const getCurrentPromotion = async (): Promise<any[]> => {
    const populatePromotion = await db.promotion.toArray();
    return populatePromotion;
  };

  const validatePromotion = async (
    items: IItemCart[],
    grandTotal: number,
    payments?: IPaymentGetPromotion[],
    promotions?: IValidatePromotionList[]
  ): Promise<{ isValid: boolean; message: string }> => {
    try {
      const newItems: IGetPromotionItem[] = items?.map((item: IItemCart) => {
        const totalPrice = getAmountItem(item);
        const itemAmount = totalPrice - getDiscountItem(item, [], true, totalPrice);
        return { item_id: item.item_id, qty: item.quantity, amount: itemAmount };
      });

      const payload: IGetPromotion = {
        items: newItems,
        current_date: new Date(),
        location_id: location?.location_id ?? 0,
        customer: {
          customer_id: customerInfo?.contact_id ?? -1,
          customer_name: customerInfo?.contact_name ?? 'Pelanggan Umum',
          customer_category_id: customerInfo?.category_id ?? 0,
        },
        grand_total: grandTotal ?? 0,
        payments,
        promotions,
      };
      await promotionRequest.validatePromotion(payload);
      return { isValid: true, message: '' };
    } catch (error: any) {
      return { isValid: false, message: error.response?.data.code };
    }
  };

  return {
    getSlashPrice,
    getSlashPriceCart,
    getPricebookCart,
    getPriceBookListByCustomer,
    getTransactionPromotion,
    getPromotionItem,
    getCurrentPromotion,
    validatePromotion,
  };
};

export default usePromotions;
