import React from 'react';
import { db } from 'services/indexdb/connection';
import { PricebookRoot } from 'types/common.types';

type Props = {
  getPriceBook: (discount: PricebookRoot) => Promise<void>;
  isGetPricebook: boolean;
  pickedPricebook: number;
  setPickedPricebook: (value: number) => void;
};

const useGetPricebook = (): Props => {
  const [pickedPricebook, setPickedPricebook] = React.useState<number>(0);
  const [isGetPricebook, setIsPricebook] = React.useState<boolean>(true);

  /**
   * Trigger when socket emit "download-discount" from server
   * @param discount
   */
  const getPriceBook = async (discount: PricebookRoot): Promise<void> => {
    setIsPricebook(true);
    if (discount.data.length > 0) await db.pricebook.bulkAdd(discount.data);
  };

  React.useEffect(() => {
    if (pickedPricebook !== 0 && pickedPricebook === 100) {
      setIsPricebook(false);
      setPickedPricebook(0);
    }
  }, [pickedPricebook]);

  return {
    getPriceBook,
    pickedPricebook,
    isGetPricebook,
    setPickedPricebook,
  };
};

export default useGetPricebook;
