import { db } from 'services/indexdb/connection';
import { BundleChild, IItemCart, Variant } from 'types/products.types';

const logBundleMovement = (title: string, inCart: Variant, itemCart: IItemCart) => {
  if (process.env.NODE_ENV === 'development') {
    console.group(title);
    console.log(`Available stock`, itemCart.available);
    console.log(`Quantity`, itemCart.quantity - 1);
    console.group('Child product of bundle in Cart list');
    console.log(`Item code`, inCart.item_code);
    console.log(`Available`, inCart.available);
    console.log('Quantity', itemCart.quantity);
    console.log('Remaining stock', inCart.available - itemCart.quantity);
    console.groupEnd();
    console.groupEnd();
  }
};

const logChildMovement = (
  title: string,
  inCart: IItemCart,
  itemCart: IItemCart,
  stockUsed: number
) => {
  console.group(title);
  console.log(`Available stock`, itemCart.available);
  console.log(`Quantity`, itemCart.quantity - 1);
  console.log('Stock used in bundle', stockUsed);
  console.group('Bundle Product');
  console.log(`Item code`, inCart.item_code);
  console.log(`Available`, inCart.available);
  console.log('Quantity', inCart.quantity);
  console.log('Remaining stock', inCart.available - inCart.quantity);
  console.groupEnd();
  console.groupEnd();
};

/**
 * Validate stock of product bundle and child product in cart list
 *
 * There are 2 cases:
 * 1. bundle product stock is enough to cover all child product in cart list
 * 2. child product stock is enough to cover all bundle product in cart list
 * so we check both cases and return true if any of them is not enough
 *
 * @param product
 * @param listCart
 * @returns true if stock of product bundle or child product is not enough
 */
export const isChildBundleEmpty = async (
  product: IItemCart,
  listCart?: IItemCart[]
): Promise<boolean> => {
  const isBundle = product.is_bundle && product.bundle_child && product.bundle_child.length;
  if (!isBundle) return childToBundle(product, listCart || []);

  return bundleToChild(product, listCart || []);
};

/**
 * Child function of isChildBundleEmpty
 *
 * Check to list cart to see if any of child product in bundle exist in cart list
 * and then check to bundle_child attributes of product bundle to calculate stock
 * of each child product in bundle product
 *
 * @param itemCart
 * @param _listCart
 * @returns true if stock of child product is not enough to cover all bundle product in cart list
 */
const childToBundle = async (itemCart: IItemCart, _listCart: IItemCart[]) => {
  const itemOnCart = _listCart?.find(
    (item) =>
      item.bundle_child &&
      item.bundle_child.length > 0 &&
      item.bundle_child.find((child) => child.item_id === itemCart.item_id)
  );
  const itemOnBundle = itemOnCart?.bundle_child?.find(
    (child) => child.item_id === itemCart.item_id
  );

  if (!itemOnCart) return false;

  const totalQty = Number(itemOnBundle?.qty) * Number(itemOnCart.quantity);
  const remainingStock = itemCart.available - totalQty;
  logChildMovement(`Product ${itemCart.item_code} picked`, itemOnCart, itemCart, totalQty);
  return itemCart.quantity > remainingStock && itemCart.pos_check_stock; // if remaining stock is less than used stock, then stock is not enough
};

/**
 * Fetch product bundle and child of bundle
 *
 * @param itemCart
 * @returns
 */
const fetchProducts = async (itemCart: IItemCart) => {
  return (
    await db.products
      .where('item_group_id')
      .anyOf(itemCart?.bundle_child?.map((child) => child.item_group_id) || [])
      .toArray()
  )
    .map((product) => product.variants)
    .flat();
};

/**
 * Child function of isChildBundleEmpty
 *
 * Check to list cart and IndexDB to see if any of child product in bundle
 * is enough to cover all bundle product in cart list
 *
 * @param itemCart
 * @param _listCart
 * @returns true if stock of child product is not enough to cover all bundle product in cart list
 */
const bundleToChild = async (itemCart: IItemCart, _listCart: IItemCart[]) => {
  const isCartEmpty = _listCart?.length === 0;
  const product = await fetchProducts(itemCart);

  if (isCartEmpty) {
    const bundleChild = itemCart.bundle_child || [];
    const productOnCart = product.filter((item) =>
      bundleChild.find((child) => child.item_id === item.item_id)
    );

    return productOnCart.some((item) => {
      const child = bundleChild.find((child) => child.item_id === item.item_id);
      const totalQty = Number(child?.qty || 0) * Number(itemCart.quantity);
      return item.available < totalQty && item.pos_check_stock;
    });
  }

  const findItemOnCart = product.filter((item) =>
    itemCart.bundle_child?.find((child) => child.item_id === item.item_id)
  );

  return findItemOnCart.some((item) => {
    const child = itemCart.bundle_child?.find((child) => child.item_id === item.item_id);
    const totalQty = child ? itemCart.quantity * (child.qty || 0) : 0;
    const remainingStock = item.available - totalQty;

    // Check if the child item is empty (i.e., its available quantity is less than the total required quantity)
    const isChildEmpty = remainingStock < 0 && item.pos_check_stock;

    logBundleMovement(`Product ${itemCart.item_code} picked`, item, itemCart);
    return isChildEmpty;
  });
};

/**
 * Update available stock of product bundle and child product in cart list
 * to validate stock of child bundle or product bundle is enough to
 * create transaction
 *
 * @param itemCart
 * @returns list item cart with updated available stock
 */
export const updateAvailableStock = (itemCart: IItemCart[]): IItemCart[] => {
  const childBundle: BundleChild[] = [];

  itemCart.forEach((item) => {
    if (item.is_bundle && item.bundle_child) {
      item.bundle_child.forEach((child) => {
        childBundle.push({
          ...child,
          qty_bundle: item.quantity,
          qty: child.qty * item.quantity,
        });
      });
    }
  });

  const results = itemCart.map((item) => {
    const childObj = childBundle.find((child) => child.item_id === item.item_id);
    if (!item.is_bundle && childObj && childObj.item_id === item.item_id) {
      const isMinus = item.available < childObj.qty;
      return {
        ...item,
        is_minus: isMinus,
        end_qty: item.available,
      };
    }

    return {
      ...item,
      end_qty: item.available,
    };
  });

  return results;
};
