import config from 'constant';
import { fetcher } from 'lib/fetcher';
import * as React from 'react';
import { selectLocation, setRegisterInfo } from 'redux/reducer/registers';
import useSwr from 'swr';
import { ICashRegister, LocationFragment } from 'types/register.types';

import { useAppDispatch, useAppSelector } from './redux';

type GetRegisterProps = {
  listRegister: ICashRegister;
  getRegisters: (locationId: number) => void;
  isLoading: boolean;
};

const useGetRegisters = (): GetRegisterProps => {
  const profile = useAppSelector((state) => state.auth.profile);

  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [locationId, setLocationId] = React.useState<number>(0);
  const { data: response } = useSwr<LocationFragment>(
    locationId !== 0 ? `sales/pos/v3/locations/${locationId}/` : null,
    fetcher
  );
  const [listRegister, setListRegister] = React.useState<ICashRegister>(
    [] as unknown as ICashRegister
  );

  const getRegisters = async (locationId: number) => {
    try {
      dispatch(setRegisterInfo(null));
      setLocationId(locationId);
    } catch (err: any) {
      throw new Error(err);
    }
  };

  React.useEffect(() => {
    setLoading(true);

    if (
      !profile?.isOwner &&
      !profile?.acls.includes(config.ACL_SALES_ALL) &&
      profile?.email !== config.SUPPORT_EMAIL &&
      profile?.email !== config.SUPPORT_EMAIL_WMS &&
      profile?.email !== config.SUPPORT_EMAIL_V2
    ) {
      setListRegister({ registers: [] });
      setLoading(false);
      return;
    }

    if (locationId && response) {
      setLoading(false);
      setListRegister({ registers: response?.registers || [] });
      dispatch(selectLocation(response));
      return;
    }
  }, [profile, locationId, response]);

  return { listRegister, getRegisters, isLoading };
};

export default useGetRegisters;
