import { useOrder, useReturn } from 'hooks';
import { setAutoSync } from 'redux/reducer/registers';
import { setSyncTransaction } from 'redux/reducer/settings';
import transactionRequest from 'services/http/transaction.request';
import { db } from 'services/indexdb/connection';
import orders from 'services/indexdb/orders';
import returnOrder from 'services/indexdb/return-order';
import { IReturnList } from 'types/return.types';
import { IOrderData } from 'types/sales.types';
import { IDetailTransaction } from 'types/transaction.types';

import { useAppDispatch, useAppSelector } from './redux';

interface IUseGetTransaction {
  syncAllTransaction: (search: string, page: number) => Promise<void>;
  sendFailTransaction: () => Promise<void>;
}

const useGetTransaction = (): IUseGetTransaction => {
  const { currentClosure, location } = useAppSelector((state) => state.register);
  const { sendOrder, cancelOrder, continuePaymentOrder } = useOrder();
  const { sendOrderReturn, cancelReturn } = useReturn();
  const dispatch = useAppDispatch();

  /**
   * Get order transaction
   */
  const getOrderTransaction = async (search = '', page: number) => {
    try {
      const orderHeader = await transactionRequest.getTransactions({
        q: search,
        page,
        pageSize: 300,
        locationId: location?.location_id as number,
        closureId: currentClosure?.closure_id as number,
      });
      const transaction: IDetailTransaction[] = [];
      await Promise.all(
        orderHeader.data.map(async (data: IDetailTransaction) => {
          const order = await transactionRequest.getTransactionDetail(data.salesorder_id);
          transaction.push({
            ...data,
            ...order,
            promotions: order.promotions,
            is_return: order.pos_return_id !== null && order.return_canceled !== true ? 1 : 0,
            hasRetur: order.pos_return_id !== null && order.return_canceled !== true ? true : false,
            pos_return_id: order.pos_return_id,
            pos_return_no: order.pos_return_no,
            is_paid: 1,
          });
        })
      );

      await db.order
        .filter((o) => {
          return o.is_paid === 1 && o.closure_id === (currentClosure?.closure_id as number);
        })
        .toArray()
        .then(async (res) => {
          const listKey = res.map((order) => order.key);
          await db.order.bulkDelete(listKey);
        });

      await db.order.bulkAdd(transaction);
      return Promise.resolve(transaction);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Get return transaction
   */
  const getReturnTransaction = async (search = '', page: number) => {
    try {
      const orderHeader = await transactionRequest.getReturn({
        q: search,
        page,
        pageSize: 300,
        locationId: location?.location_id as number,
        closureId: currentClosure?.closure_id as number,
      });
      const transaction: IReturnList[] = [];
      await Promise.all(
        orderHeader.data.map(async (data) => {
          const order = await transactionRequest.getReturnDetail(data.pos_return_id);
          const notHavePayments = [
            {
              payment_amount: parseInt(order.grand_total),
              payment_charge: 0,
              payment_fee: 0,
              payment_id: -3,
              pos_payment_id: 0,
              pos_return_id: 0,
            },
          ];
          transaction.push({
            ...data,
            ...order,
            is_return: 1,
            is_paid: 1,
            payments: !order.payments ? notHavePayments : order.payments,
            promotions: [],
          });
        })
      );

      await db.return
        .filter((o) => {
          return o.is_paid === 1 && o.closure_id === (currentClosure?.closure_id as number);
        })
        .toArray()
        .then(async (res) => {
          const listKey = res.map((retur) => retur.key);
          await db.return.bulkDelete(listKey);
        });
      await db.return.bulkAdd(transaction);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Sync all transaction with this handler
   */
  const syncAllTransaction = async (search: string, page: number) => {
    try {
      await getOrderTransaction(search, page);
      await getReturnTransaction(search, page);
      dispatch(setSyncTransaction(true));
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const sendFailTransaction = async () => {
    try {
      dispatch(setAutoSync(true));
      const failTransaction = await orders.get('', 3);
      if (failTransaction.length > 0) {
        for (const transaction of failTransaction) {
          const reqPayload = JSON.parse(transaction.request_payload);
          const payload = {
            ...reqPayload,
            is_canceled: reqPayload.is_canceled === 1 ? true : false,
          };
          await sendOrder(payload);
        }
      }

      const failCanceledTransaction = await orders.getCanceled();
      if (failCanceledTransaction.length > 0) {
        for (const transaction of failCanceledTransaction) {
          await cancelOrder(transaction);
        }
      }

      const failReturn = await returnOrder.getUnsync('');
      if (failReturn.length > 0) {
        for (const orderReturn of failReturn) {
          const reqPayload = JSON.parse(orderReturn.request_payload);
          const payload = {
            ...reqPayload,
            is_canceled: reqPayload.is_canceled === 1 ? true : false,
          };
          await sendOrderReturn(payload);
        }
      }

      const failCanceledReturn = await returnOrder.getCanceled();
      if (failCanceledReturn.length > 0) {
        for (const transaction of failCanceledReturn) {
          await cancelReturn(transaction);
        }
      }

      const failContinuePayment = await orders.getFailedContinuePayment();
      if (failContinuePayment.length > 0) {
        for (const transaction of failContinuePayment) {
          const payload = {
            ...transaction,
            pos_is_unpaid: Number(transaction.pos_is_unpaid) === 1 ? true : false,
          };
          await continuePaymentOrder(payload as unknown as IOrderData, true);
        }
      }
      dispatch(setAutoSync(false));
    } catch (error) {
      dispatch(setAutoSync(false));
      return Promise.reject(error);
    }
  };

  return {
    syncAllTransaction,
    sendFailTransaction,
  };
};

export default useGetTransaction;
