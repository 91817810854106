import React from 'react';
import { setInitSync, setSyncPayload } from 'redux/reducer/registers';
import batchnumber from 'services/indexdb/batch-number';
import { db } from 'services/indexdb/connection';
import { getPriceBookList } from 'services/indexdb/pricebook';
import products from 'services/indexdb/products';
import serialnumber from 'services/indexdb/serial-number';
import { IListStock, IProductList, IProductRoot, Variant } from 'types/products.types';

import { useAppDispatch, useAppSelector } from './redux';
import useCheckTime from './useCheckTime';

type useInitProductProps = {
  getAllProduct: (productList: IProductRoot<IProductList[]>) => Promise<void>;
  pickedItem: number;
  pickedStock: number;
  totalItem: number;
  isGetItem: boolean;
  setTotalItem: (totalItem: number) => void;
  setPickedItem: (pickedItem: number) => void;
  setPickedStock: (pickedStock: number) => void;
  getItemStock: (listStock: IProductRoot<IListStock[]>) => Promise<void>;
  mappingStockToItem: () => Promise<void>;
  updateItemPricebook: (listProduct: IProductList[]) => Promise<IProductList[]>;
};

const useInitProducts = (): useInitProductProps => {
  const [pickedItem, setPickedItem] = React.useState<number>(0);
  const [totalItem, setTotalItem] = React.useState<number>(0);
  const [isGetItem, setIsGetItem] = React.useState<boolean>(true);
  const [pickedStock, setPickedStock] = React.useState<number>(0);
  const dispatch = useAppDispatch();
  const { currentHours } = useCheckTime();
  const locationId = useAppSelector((state) => state.register.location?.location_id);
  const lastSynced = useAppSelector((state) => state.register.lastSynced);

  const getAllProduct = async (productList: IProductRoot<IProductList[]>): Promise<void> => {
    try {
      setIsGetItem(true);
      if (productList.data.length > 0) {
        const pricebooks = await db.pricebook.toArray();
        const listProduct = await Promise.all(
          productList.data.map(async (items) => {
            const variants = await Promise.all(
              items.variants.map(async (variant) => {
                const [pricebook, listSerialNumber, listBatchNumber] = await Promise.all([
                  getPriceBookList(pricebooks, variant, null, currentHours, locationId as number),
                  serialnumber.getByItemId(variant.item_id),
                  batchnumber.getByItemId(variant.item_id),
                ]);

                const itemIdKey = `item_${variant.item_id}`;
                const listPriceBookKey = `list_price_book_item_${variant.item_id}`;

                const variantData = {
                  ...variant,
                  available: 0,
                  list_serial_number: listSerialNumber,
                  list_batch_number: listBatchNumber,
                };

                if (pricebook !== undefined && pricebook[itemIdKey] === variant.item_id) {
                  return {
                    ...variantData,
                    normal_price: variant.sell_price,
                    list_price_book: pricebook[listPriceBookKey],
                  };
                } else {
                  return {
                    ...variantData,
                    list_price_book: [],
                  };
                }
              })
            );

            return {
              ...items,
              variants: variants,
            };
          })
        );

        if (lastSynced !== '') {
          await products.updateProductInformation(listProduct);
          return;
        }
        const uniqueProducts = await Promise.all(
          listProduct.filter(async (product) => {
            const existingProduct = await db.products
              .where('item_group_id')
              .equals(product.item_group_id)
              .first();
            return !existingProduct;
          })
        );

        await products.bulkAdd(uniqueProducts);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const mappingStockToItem = async (): Promise<void> => {
    const listProduct = await db.products.toArray();
    const listStock = await db.stock.toArray();
    const mergedProduct: IProductList[] = [];
    for (const product of listProduct) {
      for (const stock of listStock) {
        if (product.item_group_id === stock.item_group_id) {
          const checkVariant = product.variants.map((v) => {
            const child = stock.items?.find((i) => i.item_id === v.item_id);
            if (child) {
              return {
                ...v,
                available: child.available,
              };
            }
            return v;
          });

          if (checkVariant) {
            mergedProduct.push({
              ...product,
              variants: checkVariant,
            });
          }
        }
      }
    }

    await db.products.bulkPut(mergedProduct);
    setTimeout(() => {
      dispatch(setInitSync(false));
      dispatch(setSyncPayload({ stepDownload: '' }));
    }, 5000);
  };

  const getItemStock = async (listStocks: IProductRoot<IListStock[]>): Promise<void> => {
    try {
      if (listStocks.data.length > 0) {
        const dropStock: IListStock[] = [];
        for (const listStock of listStocks.data) {
          dropStock.push(listStock);
        }

        await db.stock.bulkAdd(dropStock);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateItemPricebook = async (listProduct: IProductList[]): Promise<IProductList[]> => {
    try {
      const updatedProduct: IProductList[] = [];
      const pricebooks = await db.pricebook.toArray();
      await Promise.all(
        listProduct?.map(async (item) => {
          const updatedVariants: Variant[] = [];
          await Promise.all(
            item.variants.map(async (variant) => {
              const pricebook = await getPriceBookList(
                pricebooks,
                variant,
                null,
                currentHours,
                locationId as number
              );
              if (
                pricebook !== undefined &&
                variant.item_id === pricebook[`item_${variant.item_id}`]
              ) {
                updatedVariants.push({
                  ...variant,
                  normal_price: variant.sell_price,
                  price_book: pricebook[`price_book_id_item_${variant.item_id}`],
                  list_price_book: pricebook[`list_price_book_item_${variant.item_id}`],
                });
              } else {
                updatedVariants.push({
                  ...variant,
                  price_book: null,
                  list_price_book: null,
                });
              }
            })
          );
          updatedProduct.push({
            ...item,
            variants: updatedVariants,
          });
        })
      );
      return Promise.resolve(updatedProduct);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (pickedItem !== 0 && pickedItem === 100) {
      setTimeout(() => {
        setIsGetItem(false);
      }, 1000);
    }
  }, [pickedItem]);

  React.useEffect(() => {
    if (pickedStock !== 0 && pickedStock === 100) {
      setTimeout(() => {
        dispatch(setInitSync(false));
      }, 1000);
    }
  }, [pickedStock]);

  return {
    getAllProduct,
    pickedItem,
    totalItem,
    isGetItem,
    setTotalItem,
    setPickedItem,
    getItemStock,
    pickedStock,
    setPickedStock,
    mappingStockToItem,
    updateItemPricebook,
  };
};

export default useInitProducts;
