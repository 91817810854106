import { TRANSACTION_SALES_TYPE } from 'constant';
import { DetailOrderTransaction } from 'types';
import { IOrderData } from 'types/sales.types';
import { IDetailTransaction } from 'types/transaction.types';

import { db } from './connection';

export default {
  /**
   * Save data to IndexDB
   * @param {IOrderData} data
   * @returns {Promise<void>}
   * @memberof IndexDB
   * @example await orders.add(data)
   */
  add: async (order: IOrderData): Promise<number> => {
    return db.order.add(order);
  },

  /**
   * Get data from IndexDB with filter type QRIS_NO_REF, NOT_SYNC, and ALL
   * @param {string} type
   * @returns {Promise<IOrderData[]>}
   * @memberof IndexDB
   */
  get: async (query = '', type: number): Promise<DetailOrderTransaction[]> => {
    const transactionOrder = await db.order
      .filter((order: IDetailTransaction) => {
        const search = new RegExp(query.toLowerCase());

        if (type === TRANSACTION_SALES_TYPE.NOT_SYNC) {
          return (
            (order.is_paid === 0 && search.test(order?.salesorder_no?.toLowerCase())) ||
            (search.test(order?.contact_name?.toLowerCase()) && query !== '')
          );
        }

        if (type === TRANSACTION_SALES_TYPE.QRIS_NO_REF) {
          return (
            (order.payments.filter(
              (payment: any) =>
                (payment.no_ref === null ||
                  payment.no_ref === undefined ||
                  payment.no_ref === '') &&
                payment.payment_id === -4
            ).length > 0 &&
              search.test(order?.salesorder_no?.toLowerCase())) ||
            (search.test(order?.contact_name?.toLowerCase()) && query !== '')
          );
        }

        if (type === TRANSACTION_SALES_TYPE.UNPAID) {
          return (
            ((order.pos_is_unpaid === true || Number(order.pos_is_unpaid) === 1) &&
              search.test(order?.salesorder_no?.toLowerCase())) ||
            (search.test(order?.contact_name?.toLowerCase()) && query !== '')
          );
        }

        return (
          search.test(order?.salesorder_no?.toLowerCase()) ||
          search.test(order?.contact_name?.toLowerCase())
        );
      })
      .reverse()
      .toArray();

    return transactionOrder.sort((a: IDetailTransaction, b: IDetailTransaction) => {
      return new Date(b.transaction_date).getTime() - new Date(a.transaction_date).getTime();
    });
  },

  /**
   * Bulk add data to IndexDB
   * @param {IDetailTransaction[]} data
   * @returns {Promise<void>}
   */
  bulkAdd: async (orders: IDetailTransaction[]): Promise<number> => {
    return db.order.bulkAdd(orders);
  },

  /**
   * Clear all data from IndexDB
   *
   * @returns {Promise<void>}
   */
  clear: async (clearAll = false): Promise<number | void> => {
    if (clearAll) {
      return db.order.clear();
    } else {
      return db.order.where('is_paid').equals(1).delete();
    }
  },

  /**
   * Update salesorder data whcih not sync and then send to server
   *
   * @param {noOrder} string - salesorder number
   * @returns {Promise<void>}
   */
  updateStatus: async (noOrder: string): Promise<void> => {
    await db.order.where('salesorder_no').equals(noOrder).modify({ is_pay: 1 });
  },

  /**
   * This function is used to get cancelled salesorder
   * @return {Promise<DetailOrderTransaction[]>}
   */
  getCanceled: async (): Promise<Promise<DetailOrderTransaction[]>> => {
    return db.order.where('is_canceled').equals(1).toArray();
  },

  /**
   * This function to get all failed continue payment order
   *
   * @returns
   */
  getFailedContinuePayment: async (): Promise<DetailOrderTransaction[]> => {
    return db.order
      .filter((item) => item.pos_is_unpaid === 1 || item.pos_is_unpaid === 0)
      .toArray();
  },
  clearByKey: async (clearAll = false, key: number | null): Promise<number | void> => {
    if (clearAll) {
      return db.order.clear();
    } else if (key) {
      return db.order.where('key').equals(key).delete();
    }
  },
};
