import { useHistory } from 'react-router-dom';
import { setAsCashier, setCurrentClosure, setOnlineStatus } from 'redux/reducer/registers';
import { resetOrder } from 'redux/reducer/sales';
import closureRequest from 'services/http/closure.request';
import { db } from 'services/indexdb/connection';

import { useAppDispatch, useAppSelector } from './redux';
import useNotification from './useNotification';

type ClosureHook = {
  getClosure: () => Promise<boolean>;
};

const useGetClosure = (): ClosureHook => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { notification } = useNotification();
  const { location, registerInfo } = useAppSelector((state) => state.register);

  const getClosure = async (): Promise<boolean> => {
    try {
      const res = await closureRequest.getCurrentClosure(
        location?.location_id as number,
        registerInfo?.register_id as number
      );
      dispatch(setCurrentClosure(res));
      return true;
    } catch (error: any) {
      if (!error.response) {
        dispatch(setOnlineStatus(false));
        return false;
      }

      if ([400, 401].includes(error.response.status)) {
        notification('', 'Silahkan buka kasir terlebih dahulu.', 'warning');

        db.cart.clear();
        // db.order.clear();
        // db.return.clear();

        dispatch(resetOrder());
        dispatch(setAsCashier(false));
        history.push('/register');
        return false;
      }

      return error;
    }
  };

  return { getClosure };
};

export default useGetClosure;
