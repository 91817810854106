import React from 'react';
import { db } from 'services/indexdb/connection';
import { SerialNumberRoot } from 'types/common.types';

type Props = {
  getSerialNumber: (serials: SerialNumberRoot) => Promise<void>;
  isGetSerialNumber: boolean;
  pickedSerialNumber: number;
  setPickedSerialNumber: (value: number) => void;
};

const useGetSerialNumber = (): Props => {
  const [pickedSerialNumber, setPickedSerialNumber] = React.useState<number>(0);
  const [isGetSerialNumber, setIsSerialNumber] = React.useState<boolean>(true);

  /**
   * Trigger when socket emit "download-serialnumber" from server
   * @param serialnumber
   */
  const getSerialNumber = async (serials: SerialNumberRoot): Promise<void> => {
    try {
      setIsSerialNumber(true);
      if (serials.data.length > 0) {
        await db.serialnumber.bulkAdd(serials.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (pickedSerialNumber !== 0 && pickedSerialNumber === 100) {
      setIsSerialNumber(false);
      setPickedSerialNumber(0);
    }
  }, [pickedSerialNumber]);

  return {
    getSerialNumber,
    pickedSerialNumber,
    isGetSerialNumber,
    setPickedSerialNumber,
  };
};

export default useGetSerialNumber;
