import { DetailOrderTransaction, IPosSetting, Maybe } from 'types';
import { ICustomerInfo, IPayments, IShippingInfo } from 'types/common.types';
import { IItemCart } from 'types/products.types';
import {
  IFreeItemsV2,
  IGetPromotionData,
  OrderPromotion,
  ResPromotionTransactionAmount,
  TransactionPromotion,
} from 'types/promotion.types';
import {
  DiscountOrder,
  IBatchNumber,
  IOrderData,
  IOrderItem,
  IOrderPayment,
  ISalesState,
  ISerialNumber,
  ITotalDetail,
  PricebookOrder,
} from 'types/sales.types';
import { Items } from 'types/transaction.types';
import { dateUTCBuyItems } from 'utils';

import {
  getAllItemsDiscount,
  getDiscountItem,
  getItemFinalDiscount,
  getItemTax,
} from './calculation';
import { formatPromotionV2, getItemPromotion, getListFreeItemsV2 } from './promotions';

/**
 * @param {IItemCart[]} items - list items on cart
 * @param {TransactionPromotion[]} promotions - list promotion of items (Min Qty, Min Amount, etc)
 * @returns {IOrderData} order
 */
export const mappingItemsOrder = (
  items: IItemCart[],
  promotions: TransactionPromotion[]
): IOrderItem[] => {
  const newArray: IOrderItem[] = items.map((item: IItemCart) => {
    const itemDiscount = getDiscountItem(item, promotions);
    return {
      item_id: item.item_id,
      price: Number(item.sell_price),
      qty_in_base: item.quantity,
      disc:
        Number(((itemDiscount / item.quantity / Number(item.sell_price)) * 100).toFixed(2)) ?? 0,
      disc_amount: getDiscountItem(item, promotions),
      tax_amount: item.tax_amount,
      tax_id: item.tax_id,
      amount: item.amount,
      notes: item.notes ?? '',
      pos_cashier_input_discount: getDiscountItem(item, promotions, false) ?? 0,
      pos_promotion_discount: getItemPromotion(item, promotions) ?? 0,
      pos_slash_price: item.pos_slash_price ?? 0,
      serial_number: item.serial_number ?? [],
      batch_number: item.batch_number ?? [],
    };
  });

  return newArray;
};

/**
 *
 * @param items IItemCart[] - list items on cart
 * @param taxIncluded  boolean - tax included or not
 * @returns {IItemCart[]} - list items on cart
 */
export const mappingItemsCartFromOrder = (items: Items[], taxIncluded: boolean): IItemCart[] => {
  const newArray: IItemCart[] = items.map((item: Items) => {
    return {
      quantity: Number(item.qty_in_base),
      use_serial_number: item.serial_number ? true : false,
      use_batch_number: item.batch_number ? true : false,
      tax_percent: Number(item.rate),
      tax_amount: Number(item.tax_amount),
      tax_id: item.tax_id,
      discount_percent: Number(item.disc),
      discount_amount: Number(item.disc_amount),
      isDiscountPercent: Number(item.disc) > 0 ? true : Number(item.disc_amount) > 0 ? false : true,
      amount: Number(item.amount),
      pos_promotion_discount: Number(item.promotion_price) ?? 0,
      pos_cashier_input_discount: 0,
      pos_slash_price: 0,
      tax_included: taxIncluded,
      item_group_id: item.item_group_id,
      item_id: item.item_id,
      item_code: item.item_code,
      item_name: item.item_name,
      is_bundle: item.is_bundle,
      variation_values: [],
      pos_check_stock: false,
      sell_price: Number(item.price),
      normal_price: Number(item.price),
      original_price: Number(item.price),
      thumbnail: item.thumbnail,
      barcode: '',
      tax_rate: Number(item.rate),
      available: 0,
      disc: Number(item.disc),
    };
  });

  return newArray;
};

/**
 * Mapping selected payment to order data
 *
 * @param payments {IPayments[]} - list payments
 * @param remainMoney {number} - remain money
 *
 * @returns lists payment after mapping data to order object
 */
export const mappingPaymentsOrder = (
  payments: IPayments[],
  remainMoney: number
): IOrderPayment[] => {
  const findOtherPayment = payments.filter((item: any) => item.payment_id !== -5);
  const newArray: IOrderPayment[] = payments.map((payment: IPayments, index: number) => {
    const newRemainMoney =
      (payment.payment_id === -1 ||
        (payment.payment_id === -5 &&
          findOtherPayment.length === 0 &&
          index === payments.length - 1)) &&
      remainMoney > 0
        ? remainMoney
        : 0;
    const paymentAmount =
      payment.payment_id === -1 ||
      (payment.payment_id === -5 && findOtherPayment.length === 0 && index === payments.length - 1)
        ? Number(payment.payment_amount) - newRemainMoney
        : payment.payment_amount;
    return {
      payment_id: payment.payment_id,
      payment_amount: payment.is_dp ? Number(payment.dp_amount) ?? 0 : Number(paymentAmount) ?? 0,
      payment_fee: 0,
      account_id: payment.account_id,
      account_name: `${payment.account_code} - ${payment.account_name}`,
      notes: payment.notes ? payment.notes : '',
      no_ref: payment.no_ref ? payment.no_ref : '',
      payment_charge:
        payment.is_dp && Number(payment.payment_amount) > Number(payment.dp_amount)
          ? Number(payment.payment_amount) - Number(payment.dp_amount)
          : Math.abs(newRemainMoney),
      created_date: dateUTCBuyItems(),
    };
  });

  return newArray.length > 0 ? newArray : [];
};

/**
 *  Mapping items on cart, customer, payments and etc
 *  and return order data
 *
 * @param order IOrderData - order data
 * @param listFreeItems IItemCart[] - list free items
 *
 * @returns detail order after mapping data & free items to order object
 */
export const mappingOrderData = (
  order: IOrderData,
  listFreeItems: IItemCart[]
): Omit<IOrderData, 'other_cost'> => {
  const freeItems = listFreeItems.map((item) => ({
    item_id: item.item_id,
    price: Number(item.sell_price),
    qty_in_base: item.quantity,
    disc: 100,
    disc_amount: Number(item.sell_price) * item.quantity,
    tax_amount: 0,
    amount: 0,
    notes: item.notes ? item.notes : '',
    pos_promotion_discount: Number(item.sell_price) * item.quantity,
    serial_number: [],
    batch_number: [],
  }));

  const newOrder = Object.assign({}, order, {
    items: [...(order.items as IOrderItem[]), ...freeItems],
    contact_id: order.customer?.contact_id,
    customer_name: order.customer?.contact_name,
  });

  delete newOrder.other_cost;
  delete newOrder.salesmen_name;
  delete newOrder.pos_is_unpaid;

  if (newOrder.shipping) {
    const newShipping = { ...newOrder.shipping };
    delete newShipping.service_category;
    delete newShipping.use_jubelio_shipment;
    delete newShipping.total_value;
    newOrder.shipping = { ...newShipping };
  }

  newOrder.is_web = true;

  return newOrder;
};

/**
 * This function is used to create discount list for order
 *
 * @param items IItemCart[] - list items on cart
 * @returns lists discount after mapping slash price and discount ID
 */
export const mappingDiscount = (items: IItemCart[]): DiscountOrder[] => {
  const arrayDiscount: DiscountOrder[] = [];
  items.forEach((item: IItemCart) => {
    if (item.slash_price) {
      arrayDiscount.push({
        promotion_price: item.slash_price[`slash_price_item_${item.item_id}`],
        item_id: item.item_id,
        discount_id: item.slash_price.discount_id,
      });
    }
  });

  return arrayDiscount;
};

type MappingCheckoutFunc = (
  sales: ISalesState,
  totalDetail: ITotalDetail,
  totalDiscount: number,
  settings: IPosSetting
) => {
  orders: IOrderData;
  items: IItemCart[];
};

export const mappingFinalItems = (
  sales: ISalesState,
  totalDetail: ITotalDetail,
  settings: IPosSetting
): IItemCart[] => {
  const items = sales.listItemCart.map((item: IItemCart) => {
    const totalAmountPerItem = Number(item.sell_price) * item.quantity;
    const itemDiscount = getDiscountItem(item, sales.listPromotionsItems);
    const itemFinalDiscount = getItemFinalDiscount({
      item,
      promotion: sales.listPromotionsItems,
      discountTransaction: totalDetail.salesDiscountAmount,
      settings: settings,
      subTotal: totalDetail.subTotalItem,
    });
    return {
      ...item,
      tax_amount: getItemTax(itemFinalDiscount, item, settings.tax_included),
      disc:
        Number(((itemDiscount / item.quantity / Number(item.sell_price)) * 100).toFixed(2)) ?? 0,
      amount: totalAmountPerItem - itemFinalDiscount,
    };
  });

  return items;
};

export const mappingCheckoutData: MappingCheckoutFunc = (
  sales,
  totalDetail,
  totalDiscount,
  settings
) => {
  return {
    orders: {
      ...sales.order,
      sub_total: totalDetail.subTotalItem,
      grand_total: totalDetail.grandTotal,
      total_disc: getAllItemsDiscount(sales),
      total_tax: totalDetail.totalTax,
      add_fee: totalDetail.otherCost + totalDetail.roundMoney,
      service_fee: settings?.discount_as_service_fee ? Number(totalDiscount) : 0,
      add_disc: !settings?.discount_as_service_fee ? Number(totalDiscount) : 0,
      pos_cashier_input_discount: totalDetail.discountTrx,
      pos_outlet_discount: Number(totalDiscount),
      pos_is_shipping: sales.pos_is_shipping,
      discounts: sales.listDiscounts,
      pricebooks: mappingPriceBookList(sales.listItemCart),
      shipping: sales.pos_is_shipping
        ? sales.shippingInfo
        : {
            shipping_full_name: sales.shippingInfo?.shipping_full_name,
            shipping_phone: sales.shippingInfo?.shipping_phone,
          },
      is_tax_included: settings?.tax_included,
      promotions: formatPromotionV2(
        [...sales.listPromotionAmount],
        [...sales.listFreeItemV2],
        sales.listValidVoucher
      ),
      pos_promotion_discount: totalDetail.salesPromotions,
      note: sales.notes_trx,
      salesmen_id: sales.salesmenInfo?.salesmen_id ?? 0,
      salesmen_name: sales.salesmenInfo?.salesmen_name ?? '',
    },
    items: mappingFinalItems(sales, totalDetail, settings),
  };
};

type MappingLocalOrderItems = (
  orderItems: IOrderItem[],
  listItemCart: IItemCart[],
  listFreeItemV2: IFreeItemsV2[]
) => IOrderItem[];
type CombinedItemRes = IItemCart & {
  disc?: number;
  is_free?: boolean;
  promotion_name?: string;
};

/**
 * This method is used to combine items in cart with free items of promotion
 *
 * @param {ISalesState} sales - sales state
 * @returns {IOrderItem[]} - list items
 */
export const mappingLocalOrderItems: MappingLocalOrderItems = (
  itemOrder,
  listItemCart,
  listFreeItemV2
) => {
  const combinedItems = [...listItemCart, ...getListFreeItemsV2(listFreeItemV2)];

  return combinedItems.map((item: CombinedItemRes) => {
    const itemOrderIndex = itemOrder.find((itemOrder) => itemOrder.item_id === item.item_id);
    if (itemOrderIndex) {
      return {
        ...item,
        qty_in_base: item.quantity,
        disc: item.is_free ? item.disc : item.discount_percent,
        disc_amount: item.is_free
          ? Number(item.sell_price) * item.quantity
          : itemOrderIndex.disc_amount,
        rate: item.tax_percent,
        price: item.sell_price,
        amount: item.is_free ? 0 : itemOrderIndex.amount,
        pos_cashier_input_discount: itemOrderIndex.pos_cashier_input_discount ?? 0,
        pos_promotion_discount: itemOrderIndex.pos_promotion_discount ?? 0,
        pos_slash_price: item.pos_slash_price ?? 0,
        tax_amount: itemOrderIndex.tax_amount ?? 0,
        original_price: item.normal_price ?? 0,
        promotion_name: item.promotion_name ?? '',
      };
    }
  }) as unknown as IOrderItem[];
};

export const mappingSerialNumberCart = (
  currentSerialNumber: ISerialNumber[] = [],
  newSerialNumber: ISerialNumber
): Maybe<ISerialNumber[]> => {
  const arraySerialNumber: ISerialNumber[] = currentSerialNumber;
  const existSerialNumber = currentSerialNumber.find(
    (item) => item.serial_no === newSerialNumber.serial_no
  );
  if (!existSerialNumber) {
    arraySerialNumber.push({
      serial_no: newSerialNumber.serial_no,
      amount: 1,
    });
  }

  return arraySerialNumber.length > 0 ? arraySerialNumber : null;
};

export const mappingBatchNumberCart = (
  currentBatchNumber: IBatchNumber[],
  newBatchNumber: IBatchNumber[]
): Maybe<IBatchNumber[]> => {
  const existBatchNumber = currentBatchNumber.find(
    (item) => item.batch_no === newBatchNumber[0].batch_no
  );
  if (existBatchNumber) {
    return currentBatchNumber.map((item) =>
      existBatchNumber.batch_no === item.batch_no ? { ...item, qty: Number(item.qty) + 1 } : item
    );
  } else {
    return newBatchNumber.concat(currentBatchNumber ?? []);
  }
};
export const mappingOrderDataContinuePayment = (
  sales: ISalesState,
  detailOrder: DetailOrderTransaction
): IOrderData => {
  const payload: IOrderData = {
    ...sales.order,
    customer: {
      b_address: detailOrder.shipping_address ?? '',
      b_area: detailOrder.shipping_area ?? '',
      b_city: detailOrder.shipping_city ?? '',
      b_post_code: detailOrder.shipping_post_code ?? '',
      b_province: detailOrder.shipping_province ?? '',
      contact_id: detailOrder.contact_id,
      contact_name: detailOrder.contact_name,
      contact_type: detailOrder.contact_type ?? 0,
      email: detailOrder.contact_email ?? '',
      location_id: detailOrder.location_id ?? 0,
      phone: detailOrder.contact_phone ?? '',
      is_update_email: false,
      category_id: -1,
    },
    salesorder_no: detailOrder.salesorder_no,
    salesorder_id: detailOrder.salesorder_id,
    service_fee: Number(detailOrder.service_fee) ?? 0,
    grand_total: Number(detailOrder.grand_total) ?? 0,
    total_disc: Number(detailOrder.total_disc) ?? 0,
    total_tax: Number(detailOrder.total_tax) ?? 0,
    sub_total: Number(detailOrder.sub_total) ?? 0,
    add_fee: Number(detailOrder.add_fee) ?? 0,
    note: detailOrder.note ?? '',
    items: detailOrder.items.map((item) => {
      return {
        ...item,
        price: Number(item.price ?? 0),
        notes: item.notes ?? '',
      };
    }),
    pos_cashier_input_discount: Number(detailOrder.pos_cashier_input_discount) ?? 0,
    is_tax_included: detailOrder.is_tax_included ?? false,
    shipping: detailOrder.shipping
      ? detailOrder.shipping
      : {
          shipping_full_name: detailOrder.shipping_full_name ?? '',
          shipping_phone: detailOrder.shipping_phone ?? '',
          shipping_address: detailOrder.shipping_address ?? '',
          shipping_area: detailOrder.shipping_area ?? '',
          shipping_city: detailOrder.shipping_city ?? '',
          shipping_province: detailOrder.shipping_province ?? '',
          shipping_post_code: detailOrder.shipping_post_code ?? '',
          shipping_cost: detailOrder.shipping_cost || 0,
          insurance_cost: detailOrder.insurance_cost ?? '',
          courier: detailOrder.courier ?? '',
        },
    pos_is_shipping: detailOrder.pos_is_shipping ?? false,
    closure_id: detailOrder.closure_id,
    transaction_date: detailOrder.transaction_date,
    request_payload: detailOrder.request_payload ?? '',
  };

  return payload;
};

export const mappingCustomerData = (detailOrder: DetailOrderTransaction): ICustomerInfo => {
  const payload: ICustomerInfo = {
    contact_id: detailOrder.contact_id,
    contact_name: detailOrder.contact_name,
    email: detailOrder.contact_email ?? '',
    is_loyalty_member: false,
    is_dropshipper: false,
    contact_type: detailOrder.contact_type ?? 0,
    phone: detailOrder.contact_phone ?? '',
    number: '',
    category_id: 0,
    store_credit: '',
    s_address: detailOrder.shipping_address ?? '',
    s_area: detailOrder.shipping_area ?? '',
    s_city: detailOrder.shipping_city ?? '',
    s_post_code: detailOrder.shipping_post_code ?? '',
    s_province: detailOrder.shipping_province ?? '',
  };

  return payload;
};

export const mappingPaymentContinueDP = (payments: IOrderPayment[]): IOrderPayment[] => {
  const newArray: IOrderPayment[] = payments.map((payment: IOrderPayment) => {
    return {
      payment_id: payment.payment_id,
      payment_amount: payment.payment_amount,
      payment_fee: payment.payment_fee,
      payment_charge: payment.payment_charge,
      account_id: payment.account_id,
      account_name: payment.account_name,
      notes: payment.notes ?? '',
      no_ref: payment.no_ref ?? '',
      created_date: dateUTCBuyItems(),
    };
  });

  return newArray;
};

export const mappingShippingInfo = (
  shippingInfo: IShippingInfo | null,
  customerInfo: ICustomerInfo | null
): IShippingInfo => {
  const dataShipping = {
    shipping_full_name: customerInfo?.contact_name || '',
    shipping_phone: customerInfo?.phone || '',
    shipping_address: shippingInfo?.shipping_address || customerInfo?.s_address || '',
    shipping_area: shippingInfo?.shipping_area || customerInfo?.s_area || '',
    shipping_city: shippingInfo?.shipping_city || customerInfo?.s_city || '',
    shipping_city_id: shippingInfo?.shipping_city_id || customerInfo?.s_city_id || '',
    shipping_district_id: shippingInfo?.shipping_district_id || customerInfo?.s_district_id || '',
    shipping_province: shippingInfo?.shipping_province || customerInfo?.s_province || '',
    shipping_province_id: shippingInfo?.shipping_province_id || customerInfo?.s_province_id || '',
    shipping_post_code: shippingInfo?.shipping_post_code || customerInfo?.s_post_code || '',
    shipping_subdistrict: shippingInfo?.shipping_subdistrict || customerInfo?.s_subdistrict || '',
    shipping_subdistrict_id:
      shippingInfo?.shipping_subdistrict_id || customerInfo?.s_subdistrict_id || '',
    shipping_cost: shippingInfo?.shipping_cost || 0,
    insurance_cost: shippingInfo?.insurance_cost,
    courier: shippingInfo?.courier || shippingInfo?.courier || '',
    service_category: shippingInfo?.service_category || 0,
    use_jubelio_shipment: shippingInfo?.use_jubelio_shipment ?? false,
  };

  return dataShipping;
};

export const mappingOrderPromotion = (
  promotionAmount: ResPromotionTransactionAmount[],
  promotionItem: IGetPromotionData[]
): OrderPromotion[] => {
  const amount = promotionAmount.map((p) => ({
    promotion_id: p.promotion.promotion_id,
    promotion_name: p.promotion.promotion_name,
  }));
  const item = promotionItem.map((p) => ({
    promotion_id: p.promotion_id,
    promotion_name: p.promotion_name,
  }));

  const combinePromotion = [...amount, ...item];
  const result: OrderPromotion[] = Object.values(
    combinePromotion.reduce((acc: any, current) => {
      acc[current.promotion_id] = current;
      return acc;
    }, {})
  );

  return result;
};

export const mappingPriceBookList = (listItemCart: IItemCart[] = []): PricebookOrder[] => {
  const listPriceBook: PricebookOrder[] = [];
  for (const item of listItemCart) {
    if (item.price_book) {
      listPriceBook.push(item.price_book);
    }
  }
  return listPriceBook;
};
