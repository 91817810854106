import { alert } from 'constant/messages';
import { useNotification, usePromotions } from 'hooks';
import { useDispatch } from 'react-redux';
import { chooseItem, removeItem } from 'redux/reducer/sales';
import { IItemCart } from 'types/products.types';
import { isChildBundleEmpty } from 'utils/stock-validation';

import { useAppSelector } from './redux';

interface PropsStock {
  checkingStockAvailable: (variant: IItemCart, type?: string) => void;
}

const useStock = (): PropsStock => {
  const toastScanQty = 'toast-scan-qty';
  const CLOSE_ALERT_DURATION = 2000; // 2 second

  const dispatch = useDispatch();

  //Redux Store
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const customerInfo = useAppSelector((state) => state.sales.customerInfo);

  const { getSlashPrice } = usePromotions();
  const { notification } = useNotification();

  // validate stock and add item to cart
  const checkingStockAvailable = async (variant: IItemCart, feat?: string) => {
    const itemOnCart = listItemCart.find((item) => item.item_id === variant.item_id);
    const mappingItem = itemOnCart
      ? { ...itemOnCart, quantity: itemOnCart.quantity + 1, available: itemOnCart.available }
      : variant;

    const isChildAvailable = await isChildBundleEmpty(mappingItem, listItemCart);

    if (isChildAvailable) {
      notification(toastScanQty, alert.stock_empty, 'warning', CLOSE_ALERT_DURATION);
      return;
    }

    const slashPrice = await getSlashPrice([variant], customerInfo);
    const slash_price = `slash_price_item_${variant.item_id}`;

    if (feat === 'save_transaction') dispatch(removeItem(variant.item_id));

    if (!variant.pos_check_stock) {
      dispatch(
        chooseItem({
          ...variant,
          slash_price: slashPrice,
          pos_slash_price:
            slashPrice && slashPrice[slash_price] ? slashPrice[slash_price].total_slash_price : 0,
        })
      );
      return;
    }

    if (!itemOnCart && variant.available <= 0) {
      notification(
        toastScanQty,
        'Stok habis atau tidak mencukupi',
        'warning',
        CLOSE_ALERT_DURATION
      );
      return;
    }

    if (
      variant.available === 0 ||
      variant.available < 0 ||
      Number(itemOnCart?.quantity ?? 0) >= variant.available
    ) {
      notification(
        toastScanQty,
        'Stok habis atau tidak mencukupi',
        'warning',
        CLOSE_ALERT_DURATION
      );
      return;
    }

    dispatch(
      chooseItem({
        ...variant,
        slash_price: slashPrice,
        pos_slash_price:
          slashPrice && slashPrice[slash_price] ? slashPrice[slash_price].total_slash_price : 0,
      })
    );
  };

  return {
    checkingStockAvailable,
  };
};

export default useStock;
